import './App.css';
import StepsFlow from "./components/StepsFlow/StepsFlow";
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import { initGA, logPageView } from './analytics';
import { useEffect } from 'react';

// We need to create a separate component to use useLocation
function AppContent() {
    const location = useLocation();

    useEffect(() => {
        logPageView(location.pathname);
    }, [location]);

    return (
        <div className="App">
            <div className="container">
                <StepsFlow />
            </div>
        </div>
    );
}

function App() {
    useEffect(() => {
        initGA();
    }, []);
    
    return (
        <BrowserRouter>
            <AppContent />
        </BrowserRouter>
    );
}

export default App;
