import React, {useState} from "react";
import "./Step2.css";

const MAX_COUNT = 5;

const Step2 = ({ nextStep, updateFormData, settings, formData }) => {
    const [selectedInterests, setSelectedInterests] = useState(formData.interests || []);

    const handleInterestSelect = (interest) => {
        if (selectedInterests.includes(interest)) {
            const newInterests = selectedInterests.filter((i) => i !== interest);
            setSelectedInterests(newInterests);
            updateFormData({ interests: newInterests });
        } else if (selectedInterests.length < MAX_COUNT) {
            const newInterests = [...selectedInterests, interest];
            setSelectedInterests(newInterests);
            updateFormData({ interests: newInterests });
        }
    };

    return (<>
        <h1>Your Interests</h1>
        <div className="inner-container-interests">
            <h3 style={{marginTop: '100px'}}>Pick up to five interests</h3>
            <div className="interests" style={{marginBottom: '100px'}}>
                {settings.interests.map((interest) => (
                    <div
                        className={selectedInterests.includes(interest.name) ? 'active toggle-button' : 'toggle-button'}
                        key={interest.name}
                        onClick={() => handleInterestSelect(interest.name)}
                    >
                        {interest.name}
                    </div>
                ))}
            </div>
            <div className="next-button-row">
                <button onClick={() => nextStep()} disabled={selectedInterests.length === 0}>Next</button>
            </div>

        </div>
    </>)
}

export default Step2;