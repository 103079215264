import React, {useEffect, useState} from 'react';
import "./Step5.css";
import axios from "axios";
import {BASE_URL} from "../../config";



const Step5 = ({ nextStep, updateFormData, settings, formData }) => {

    const [otp, setOtp] = useState(new Array(6).fill(""));
    const firstReqDone = React.useRef(false);

    useEffect(() => {
        if(!firstReqDone.current){
            sendOtpRequest();
            firstReqDone.current = true;
        }
    }, []);



    const sendOtpRequest = async () => {
        try {
            const response = await axios.post(`${BASE_URL}/whastapp/register`, {
                countryCode: formData.countryCode,
                phoneNumber: formData.phoneNumber,
                ageAgree: formData.agreeDate,
                termsAgree: formData.termsDate,
                avatarId: formData.avatarId,
                interests: formData.interests
            });
            if (response.status === 200) {

            } else {
                alert("Request failed: " + response.data.error);
            }
        } catch (error) {
            alert(error.response?.data?.error || error.message);
        }
    };


    const handleChange = (element, index) => {
        const value = element.value.slice(-1);
        if (value.match(/[0-9]/)) {
            const newOtp = [...otp];
            newOtp[index] = value;
            setOtp(newOtp);
            updateFormData(newOtp.join(""));

            if (element.nextSibling) {
                element.nextSibling.focus();
            }
        }
    };

    const isOtpComplete = () => otp.every((digit) => digit !== "");

    const finish = async ()=> {
        try {
            const response = await axios.post(`${BASE_URL}/whastapp/verify-otp`, {
                countryCode: formData.countryCode,
                phoneNumber: formData.phoneNumber,
                otp: otp.join(""),
                avatarId: formData.avatarId,
            });
            if (response.status === 200) {
                if (typeof nextStep === 'function') {
                    nextStep();
                } else {
                    console.error('nextStep prop is not a function');
                }
            } else {
                alert("Request failed: " + response.data.error);
            }
        } catch (error) {
            alert(error.response?.data?.error || error.message)
        }
    };

    return (<>
        <h1>Verify</h1>
        <div className="inner-container">
            <div style={{textAlign: "center", marginTop: "180px"}}>
                <h3>We’ve sent an OTP to {formData.countryCode + formData.phoneNumber}</h3>
                <div className="otp-fields">
                    {otp.map((value, index) => (
                        <input
                            key={index}
                            type="tel"
                            maxLength="1"
                            value={value}
                            onChange={(e) => handleChange(e.target, index)}
                            className="rounded-input"
                        />
                    ))}
                </div>
                <h3 style={{marginBottom: "20px"}}>Didn’t get the OTP?</h3>
                <h3 onClick={sendOtpRequest} style={{cursor: "pointer"}}>
                    Resend Code
                </h3>
            </div>

            <div className="next-button-row" style={{marginTop: '65px'}}>
                <button onClick={() => finish()}
                        disabled={!isOtpComplete()}>Finish
                </button>
            </div>
        </div>
    </>)
}

export default Step5;