import React, {useEffect, useRef, useState} from 'react';
import './Step1.css'
import { useLocation } from 'react-router-dom';

const Step1 = ({ nextStep, updateFormData, settings, isLoading, formData }) => {
    const [selectedGender, setSelectedGender] = useState('f');
    const location = useLocation();

    const hasCalledNextStep = useRef(false);

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const avatarIdParam = queryParams.get('avatarId');
        const genderParam = queryParams.get('gender');

        if (avatarIdParam && !formData.hasLoadedParam && !hasCalledNextStep.current) {
            updateFormData({ avatarId: avatarIdParam, hasLoadedParam: true });
            nextStep();
            hasCalledNextStep.current = true;
            return;
        }

        if (genderParam) {
            setSelectedGender(genderParam === 'male' ? 'm' : 'f');
        }
    }, [location.search, formData.hasLoadedParam, updateFormData, nextStep]);

    const handleAvatarSelect = (avatar) => {
        updateFormData({avatarId: avatar.id});
        nextStep();
    };


    const filteredAvatars = settings.avatars.filter((avatar) =>
        selectedGender ? avatar.gender === selectedGender : true
    );

    const AvatarPlaceHolder = () => <div
        className="avatar">
        <div className="upper"></div>
        <div className="data-row">
            <div className="image-wrapper">
                <div className="placeholder-container">
                    <div className="animated-background"></div>
                </div>
            </div>
            <div className="avatar-data">
                <label>AGE & LOCATION:</label>
                <br/>
                <br/> <br/>
                <label>INTERESTS:</label>
                <br/>
            </div>
        </div>
    </div>;

    return (
        <>
            <h1>Choose Your Mistress</h1>
            <div className="toggle-list" style={{marginBottom: '20px'}}>
                <div className={selectedGender === "f" ? "active toggle-button" : "toggle-button"}
                     onClick={() => setSelectedGender('f')}>Female
                </div>
                <div className={selectedGender === "m" ? "active toggle-button" : "toggle-button"}
                     onClick={() => setSelectedGender('m')}>Male
                </div>
            </div>
            <div className="avatar-row">
                {!isLoading ? filteredAvatars.map((avatar) => (
                    <div
                        className="avatar"
                        key={avatar.id}
                        onClick={() => handleAvatarSelect(avatar)}
                    >
                        <div className="upper">{avatar.display_name}</div>
                        <div className="data-row">
                            <div className="image-wrapper">
                                <img src={avatar.profile_img_url} alt="Avatar image"/>
                            </div>
                            <div className="avatar-data">
                                <label>AGE & LOCATION:</label>
                                {avatar.age}yrs old, {avatar.location}
                                <br/> <br/>
                                <label>INTERESTS:</label>
                                {avatar.interests}
                            </div>
                        </div>
                    </div>
                )) : <>
                    <AvatarPlaceHolder/>
                    <AvatarPlaceHolder/>
                    <AvatarPlaceHolder/>
                </>}
            </div>
        </>
    );
};

export default Step1;