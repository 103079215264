import React, { useState } from 'react';
import {getCurrentFormattedDateTime} from "../../tools/dateTools";
import "./Step4Whatsapp.css";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

const Step4Whatsapp = ({ nextStep, updateFormData, formData }) => {
    const [countryCode, setCountryCode] = useState(formData.countryCode || '');
    const [phoneNumber, setPhoneNumber] = useState(formData.countryCode ? formData.countryCode + formData.phoneNumber : '' );
    const [agreeDate, setAgreeDate] = useState(formData.agreeDate || null);
    const [termsDate, setTermsDate] = useState(formData.termsDate || null);



    const handlePhoneChange = (value, country) => {
        setPhoneNumber(value.replace(`+${country.dialCode}`, ''));
        setCountryCode(country.dialCode)
    };

    const handleChargesChange = (event) => {
        setTermsDate(event.target.checked ? getCurrentFormattedDateTime() : null);
    };

    const handleTermsChange = (event) => {
        setAgreeDate(event.target.checked ? getCurrentFormattedDateTime() : null);

    };

    const handleNextStep = () => {
        updateFormData({ countryCode, phoneNumber: phoneNumber.replace(countryCode,''), termsDate, agreeDate });
        nextStep();
    };

    return (
        <>
            <h1 style={{marginBottom: '120px'}}>Enter your number</h1>
            <div className="inner-container">
                <h3>Enter your phone number</h3>
                <div className="phone-row">
                    <PhoneInput
                        value={`${phoneNumber}`}
                        onChange={handlePhoneChange}
                        country={"us"}
                        enableSearch={true}
                    />
                </div>
                <div className="check-wrapper">
                    <input type="checkbox" onChange={handleChargesChange} checked={termsDate != null}
                           id="charges-check"/>
                    <label htmlFor="charges-check">I confirm that I am over 18 years old and agree to the <a href="https://www.mymistress.ai/terms/underage-policy"
                                                                 target="_blank">Underage Policy</a></label>
                </div>

                <div className="check-wrapper">
                    <input type="checkbox" onChange={handleTermsChange} checked={agreeDate != null} id="terms-check"/>
                    <label htmlFor="terms-check">I agree to the <a href="https://www.mymistress.ai/terms/terms-of-business" target="_blank">Terms of Service</a> and 
                     <a href="https://www.mymistress.ai/terms/privacy-policy" target="_blank">Privacy Policy</a></label>
                </div>
                <div className="next-button-row" style={{marginTop: '65px'}}>
                    <button onClick={() => handleNextStep()}
                            disabled={countryCode.length === 0 || phoneNumber.length === 0 || !agreeDate || !termsDate}>Next
                    </button>
                </div>
            </div>
        </>
    );
};

export default Step4Whatsapp;