import React, { useState } from 'react';
import './Step3.css';
import WhatsappImage from './whatsapp.png';
import TelegramImage from './telegram.png';

const Step3 = ({ nextStep, updateFormData, settings }) => {


    const selectApp = (chatApp)=>{
        updateFormData({chatApp});
        nextStep();
    };
    return (<>
        <h1>Preferred chat method</h1>
        <div className="inner-container">
            <h3 style={{
                marginTop: '100px'
            }}>How shall we chat with you?</h3>
            <div className="chat-app" onClick={() => selectApp('whatsapp')}>
                <img src={WhatsappImage}/>
                <div>Whatsapp</div>
            </div>
            <div className="chat-app" onClick={() => selectApp('telegram')}>
                <img src={TelegramImage}/>
                <div>Telegram</div>
            </div>
        </div>
    </>)
}

export default Step3;