export const getStepPageName = (stepNumber) => {
  const stepNames = {
    1: 'Choose Your Mistress',
    2: 'Select Interests',
    3: 'Choose Chat Method',
    4: 'Enter Contact Details',
    5: 'Verify Contact',
    6: 'Setup Complete'
  };
  
  return stepNames[stepNumber] || `Step ${stepNumber}`;
}; 