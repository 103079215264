import React, { useState, useEffect } from 'react';
import {BASE_URL} from "../../config";
import axios from 'axios';
import Step1 from '../Step1/Step1';
import Step3 from '../Step3/Step3';
import Step2 from '../Step2/Step2';
import Step4Whatsapp from '../Step4Whatsapp/Step4Whatsapp';
import Step5 from '../Step5/Step5';
import SettingsMock from "../../mock/settings.json";
import BackImage from "./back.svg";
import "./StepsFlow.css";
import Step4Telegram from "../Step4Telegram/Step4Telegram";
import Step6 from "../Step6/Step6";
import { logPageView } from '../../analytics';
import { getStepPageName } from '../../analytics/pageNames';

const StepsFlow = () => {
    const [currentStep, setCurrentStep] = useState(1);
    const [formData, setFormData] = useState({
        chatApp: '', // telegram||whatsapp
        avatarId: '',
        interests: [],
        phoneNumber: '',
        countryCode: '',
        termsDate: '',
        agreeDate: '',
        hasLoadedParam: false,
    });
    const [settings, setSettings] = useState(SettingsMock); // Store settings data here
    const [loading, setLoading] = useState(true); // Track loading state for settings
    const [error, setError] = useState(null); // Track any errors from fetching

    useEffect(() => {
        const fetchSettings = async () => {
            try {
                // api key header from env
                const headers = {
                    'x-api-key': `${process.env.REACT_APP_API_KEY}`
                };
                const response = await axios.get(`${BASE_URL}/settings`, {headers}); // Replace with your endpoint URL
                setSettings(response.data);
            } catch (error) {
                setError(error.response?.data?.error ||error.message || 'Failed to fetch settings');
            } finally {
                setLoading(false);
            }
        };

        fetchSettings();
    }, []);

    useEffect(() => {
        const pageName = getStepPageName(currentStep);
        document.title = `${pageName} | My Mistress AI`;
        logPageView(`/onboarding/${pageName.toLowerCase().replace(/\s+/g, '-')}`);
    }, [currentStep]);

    const nextStep = () => {
        // console.log("next step called");
        setCurrentStep((prev) => prev + 1);
    };

    const prevStep = () => {
        setCurrentStep((prev) => prev - 1);
    };

    const updateFormData = (newData) => {
        setFormData({ ...formData, ...newData });
    };

    const backClicked = ()=> {
        if(currentStep === 1){
            window.history.back();
        } else {
            prevStep();
        }
    }

    const renderStep = () => {
        switch (currentStep) {
            case 1:
                return <Step1 nextStep={nextStep} updateFormData={updateFormData} formData={formData} settings={settings} />;
            case 2:
                return <Step2 nextStep={nextStep} updateFormData={updateFormData} formData={formData} settings={settings} />;
            case 3:
                return <Step3 nextStep={nextStep} prevStep={prevStep} updateFormData={updateFormData} formData={formData} settings={settings} />;
            case 4:
                return formData.chatApp === 'telegram' ?
                    <Step4Telegram nextStep={nextStep} prevStep={prevStep} formData={formData} settings={settings} />
                    :<Step4Whatsapp nextStep={nextStep} prevStep={prevStep} updateFormData={updateFormData} formData={formData} settings={settings} />;
            case 5:
                return <Step5 nextStep={nextStep} formData={formData} settings={settings} updateFormData={updateFormData} />;
            case 6:
                return <Step6></Step6>;
            default:
                return <></>;
        }
    };

    if (loading) return <Step1 nextStep={nextStep} updateFormData={updateFormData} formData={formData} settings={{avatars: []}} isLoading={true} />;
    if (error) return <p className="flow-error">Error loading settings: {error}</p>;

    return (
        <div className="steps-flow">
            <div className="back-button" onClick={()=> backClicked()}>
                <img src={BackImage}/> Back
            </div>
            <div>{renderStep()}</div>
        </div>
    );
};

export default StepsFlow;