
import CheckImage from "./check.jpeg";

const Step6 = () => {

    return (<div className="inner-container" style={{textAlign: "center", paddingTop: "60px"}}>
        <img src={CheckImage}/>
        <h1 style={{width: "276px", margin: "75px auto 0", lineHeight: "50px", fontWeight: 500}}>
            Finished
            <br/><br/>
            Await instructions from your Mistress.
        </h1>
    </div>);
};

export default Step6


